<template>
    <div id="secteurs" class="page-wrapper page-secteurs">
        <!-- Hero -->
        <SmallHero v-if="pageIsLoaded" :hero="content.hero" />

        <!-- Intro Desc -->
        <section class="intro-desc site-max-width">
            <article class="content" v-if="pageIsLoaded">
                <h2 class="title sup-title" data-inview="fadeInUp" data-delay="100">{{ content.introDescription1.surtitre }}</h2>
                <h3 class="subtitle medium-title" data-inview="fadeInUp" data-delay="300"><span v-html="content.introDescription1.titre"></span></h3>
            </article>
            <div class="desc" data-inview="fadeInUp" data-delay="400">
                <p class="small-text">
                    <span v-if="pageIsLoaded" v-html="content.introDescription1.texte"></span>
                </p>
            </div>
        </section>

        <!-- Showcase -->
        <section class="showcase site-max-width" v-if="pageIsLoaded">
            <h4 class="small-title top-title" data-inview="fadeInUp" data-delay="100">{{ content.introBlock1.surtitre }}</h4>
            <div class="featured" data-inview="fadeInUp" data-delay="200" v-if="content.featuredImageVideo" >
                <img :src="content.featuredImageVideo.image.url" :alt="content.featuredImageVideo.image.titre">
            </div>
            <div class="grid-items team col-4">
                <div v-for="(item, i) in content.gridThumbs" :key="'e' + i" class="item" data-inview="fadeInUp" data-delay="300">
                    <img :src="item.image.url" :alt="item.image.titre">
                    <div class="team-titles">
                        <h5 class="small-title team-title">{{ item.titre }}</h5>
                        <p class="regular-text team-text">{{ item.texte }}</p>
                    </div>
                </div>
            </div>
        </section>

        <!-- Intro Desc -->
        <section class="intro-desc site-max-width" v-if="pageIsLoaded && content.introDescription2.surtitre">
            <article class="content">
                <h2 class="title sup-title" data-inview="fadeInUp" data-delay="100">{{ content.introDescription2.surtitre }}</h2>
                <h3 class="subtitle medium-title" data-inview="fadeInUp" data-delay="300"><span v-html="content.introDescription2.titre"></span></h3>
            </article>
            <div class="desc" data-inview="fadeInUp" data-delay="400">
                <p class="small-text">
                    <span v-if="pageIsLoaded" v-html="content.introDescription2.texte"></span>
                </p>
            </div>
        </section>

        <!-- Showcase -->
        <section class="showcase site-max-width">
            <div class="grid-items col-2" v-if="pageIsLoaded">
                <div v-for="(item, i) in content.gridImages2" :key="'i-' + i" class="item" data-inview="fadeInUp" data-delay="200">
                    <img :src="item.image.url" :alt="item.image.titre">
                </div>
            </div>
        </section>

        <div class="simple-spacer"></div>

        <!-- Mission -->
        <Mission v-if="pageIsLoaded" id="mission" :introBlock="globals.footer.introBlock1" :gridIcones="globals.footer.gridIcones" />

        <!-- Split -->
        <section class="split site-max-width" v-if="pageIsLoaded">
            <div class="left" data-inview="revealLeft" data-delay="100">
                <img :src="content.split.image.url" :alt="content.split.image.titre">
            </div>
            <div class="right">
                <h2 class="sup-title suptitle" data-inview="fadeInUp" data-delay="100">{{ content.split.surtitre }}</h2>
                <h3 class="small-title title" data-inview="fadeInUp" data-delay="300"><span v-html="content.split.titre"></span></h3>
                <p class="regular-text text" data-inview="fadeInUp" data-delay="400">
                    <span v-html="content.split.texte"></span>
                </p>
                <a :href="content.split.boutonUrl[$route.meta.lang] ? content.split.boutonUrl[$route.meta.lang] : content.split.boutonUrl" class="site-btn" title="Rejoignez notre équipe" data-inview="fadeInUp" data-delay="500">
                    {{ content.split.bouton.customText }}
                </a>
            </div>
        </section>

        <div class="page-ender"></div>
    </div>
</template>

<script>
import { initInViewAnimations } from '@/plugins/InViewAnimations'
import { mapGetters, mapState } from 'vuex'

import Flickity from 'flickity'
import GLightbox from 'glightbox'
// import SvgLivraison from '@/components/svg/SvgLivraison'
import SmallHero from '@/components/SmallHero'
import Mission from '@/components/Mission'

export default {
    name: 'About',

    components: {
        // SvgLivraison,
        SmallHero,
        Mission,
    },

    data() {
        return {
            expertisesCarousel: null,
        }
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    GLightbox()

                    setTimeout(() => {
                        this.expertisesCarousel = new Flickity(document.querySelector('.expertises-carousel'), {
                            cellAlign: 'left',
                            wrapAround: true,
                            lazyLoad: 1,
                            imagesLoaded: true,
                            prevNextButtons: false,
                            pageDots: false,
                        })

                        initInViewAnimations()
                    }, 155)
                }
            },
            immediate: true,
        },
    },

    mounted () {

    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        expertiseGoTo(direction) {
            if (direction === 'prev') {
                this.expertisesCarousel.previous()
                return
            }
            this.expertisesCarousel.next()
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
